import {
  agreeApproval,
  rejectApproval,
  agreeApprovalBatch,
  rejectApprovalBatch,
  getApprovalDetail,
  todoList,
  doneList,
  applyList,
  ccList,
} from '../../../api/finance/financeAffiliate';

const defaultPagesize = 10;

export default {
  components: {},
  data() {
    return {
      activeName: 'todo',
      todoNum: 0,
      completedNum: 0,
      launchNum: 0,
      ccNum: 0,
      loading: {
        todo: false,
        completed: false,
        cc: false,
        apply: false,
        detail: false,
      },
      multipleSelection: [],
      copyArr: [],
      currentDetail: {},
      todoList: { list: [], page: 1, pagesize: defaultPagesize, total: 0, totalPage: 0 },
      completedList: { list: [], page: 1, pagesize: defaultPagesize, total: 0, totalPage: 0 },
      ccList: { list: [], page: 1, pagesize: defaultPagesize, total: 0, totalPage: 0 },
      applyList: { list: [], page: 1, pagesize: defaultPagesize, total: 0, totalPage: 0 },
      drawerShow: {
        visible: false,
        title: '',
        comment: '',
        type: '',
      },
      commentsIndex: [],
      attachmentsIndex: [],
      paginations: {
        pageSizes: [10, 20, 50, 100, 600], //每页显示多少条
        layout: 'sizes, prev, pager, next', // 翻页属性
      },
    };
  },
  mounted() {
    this.freshTodoList();
    this.freshCompletedList();
    this.freshCcList();
    this.freshApplyList();
  },
  computed: {},

  methods: {
    handleClick(tab) {
      switch (tab.name) {
        case 'todo':
          this.freshTodoList();
          break;
        case 'completed':
          this.freshCompletedList();
          break;
        case 'cc':
          this.freshCcList();
          break;
        case 'apply':
          this.freshApplyList();
          break;
      }
    },
    todoDetail(row, column) {
      if (column && column.label == 'Action') {
        return;
      }
      this.drawerShow.title = 'TODO Detail';
      this.drawerShow.comment = '';
      this.drawerShow.type = 'todo';
      this.loading.detail = true;
      getApprovalDetail({ id: row.id }).then((resp) => {
        this.loading.detail = false;
        if (resp.code == 200) {
          let data = resp.result;
          data.note = JSON.parse(data.note).join(' , ');
          this.currentDetail = data;
        }
      });
      this.drawerShow.visible = true;
    },
    seeDetail(row, column) {
      if (column && column.label == 'Action') {
        return;
      }
      this.drawerShow.title = 'Approval Detail';
      this.drawerShow.comment = '';
      this.drawerShow.type = 'completed';
      this.loading.detail = true;
      getApprovalDetail({ id: row.id }).then((resp) => {
        this.loading.detail = false;
        if (resp.code == 200) {
          let data = resp.result;
          data.note = JSON.parse(data.note).join(' , ');
          this.currentDetail = data;
        }
      });
      this.drawerShow.visible = true;
    },
    todoBeforeClose() {
      this.drawerShow.visible = false;
      this.drawerShow.title = '';
      this.currentDetail = {};
    },
    freshTodoList() {
      this.loading.todo = true;
      todoList({ status: 1, page: this.todoList.page, pageSize: this.todoList.pagesize })
        .then((resp) => {
          this.loading.todo = false;
          if (resp.code == 200) {
            this.todoList.list = resp.result;
            this.todoList.page = resp.currentPage;
            this.todoList.pagesize = resp.pageSize;
            this.todoList.total = resp.total;
            this.todoList.totalPage = resp.totalPage;
            this.todoNum = resp.total;
          }
        })
        .catch(() => {
          this.loading.todo = false;
        });
    },
    freshCompletedList() {
      this.loading.completed = true;
      doneList({ page: this.completedList.page, pageSize: this.completedList.pagesize })
        .then((resp) => {
          this.loading.completed = false;
          if (resp.code == 200) {
            this.completedList.list = resp.result;
            this.completedList.page = resp.currentPage;
            this.completedList.pagesize = resp.pageSize;
            this.completedList.total = resp.total;
            this.completedList.totalPage = resp.totalPage;
            this.completedNum = resp.total;
          }
        })
        .catch(() => {
          this.loading.completed = false;
        });
    },
    freshCcList() {
      this.loading.cc = true;
      ccList({ page: this.ccList.page, pageSize: this.ccList.pagesize })
        .then((resp) => {
          this.loading.cc = false;
          if (resp.code == 200) {
            console.log(12145);
            this.ccList.list = resp.result;
            this.ccList.page = resp.currentPage;
            this.ccList.pagesize = resp.pageSize;
            this.ccList.total = resp.total;
            this.ccList.totalPage = resp.totalPage;
            if (resp.total !== undefined) {
              this.ccNum = resp.total;
            } else {
              this.ccNum = 0;
            }
          }
        })
        .catch(() => {
          this.loading.cc = false;
        });
    },
    freshApplyList() {
      this.loading.apply = true;
      applyList({ page: this.applyList.page, pageSize: this.applyList.pagesize })
        .then((resp) => {
          this.loading.apply = false;
          if (resp.code == 200) {
            this.applyList.list = resp.result;
            this.applyList.page = resp.currentPage;
            this.applyList.pagesize = resp.pageSize;
            this.applyList.total = resp.total;
            this.applyList.totalPage = resp.totalPage;
            this.launchNum = resp.total;
          }
        })
        .catch(() => {
          this.loading.apply = false;
        });
    },
    openAgreeApproval(id) {
      this.$prompt('input remark message', 'Remark', {
        confirmButtonText: 'agree',
        cancelButtonText: 'cancel',
      })
        .then(({ value }) => {
          agreeApproval({ id: id, noteMsg: value }).then((resp) => {
            if (resp.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.freshTodoList();
              this.freshCompletedList();
            } else {
              this.$message.error('Update  Error:' + resp.message);
            }
          });
        })
        .catch(() => {});
    },
    doRejectApproval() {
      let msg = this.drawerShow.comment ? this.drawerShow.comment : 'default reject';
      this.$confirm(msg, 'Reject Comment', {
        confirmButtonText: 'reject',
        cancelButtonText: 'cancel',
      })
        .then(() => {
          rejectApproval({ id: this.currentDetail.id, noteMsg: msg }).then((resp) => {
            if (resp.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.drawerShow.visible = false;
              this.freshTodoList();
              this.freshCompletedList();
            } else {
              this.$message.error('Update  Error:' + resp.message);
            }
          });
        })
        .catch(() => {});
    },
    doAgreeApproval() {
      let msg = this.drawerShow.comment ? this.drawerShow.comment : 'default agree';
      agreeApproval({ id: this.currentDetail.id, noteMsg: msg }).then((resp) => {
        if (resp.success === true) {
          this.$message({
            message: 'Update Success',
            type: 'success',
          });
          this.drawerShow.visible = false;
          this.freshTodoList();
          this.freshCompletedList();
        } else {
          this.$message.error('Update  Error:' + resp.message);
        }
      });
    },
    formatValue(val) {
      if (val === '1') {
        return '提交';
      } else if (val === '4') {
        return '驳回';
      } else if (val === '3') {
        return '通过';
      } else {
        return '待审批';
      }
    },
    pageSizeChange(size, type) {
      switch (type) {
        case 'todo':
          this.todoList.pageSize = size;
          break;
        case 'completed':
          this.completedList.pageSize = size;
          break;
        case 'cc':
          this.ccList.pageSize = size;
          break;
        case 'apply':
          this.applyList.pageSize = size;
          break;
      }
      this.pageChange(1, type);
    },
    pageChange(page, type) {
      switch (type) {
        case 'todo':
          this.todoList.page = page;
          this.freshTodoList();
          break;
        case 'completed':
          this.completedList.page = page;
          this.freshCompletedList();
          break;
        case 'cc':
          this.ccList.page = page;
          this.freshCcList();
          break;
        case 'apply':
          this.applyList.page = page;
          this.freshApplyList();
          break;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let idFinance = this.multipleSelection[i].id;
        if (idFinance) {
          this.copyArr.push(this.multipleSelection[i].id);
        }
      }
    },
    approvalAgreeBatch() {
      if (this.copyArr.length === 0) {
        this.$message.error('Please select the item to be approved');
        return;
      }
      this.$prompt('input remark message', 'Remark', {
        confirmButtonText: 'agree',
        cancelButtonText: 'cancel',
      })
        .then(({ value }) => {
          let targetList = this.copyArr.slice();
          agreeApprovalBatch({ ids: targetList, noteMsg: value }).then((resp) => {
            if (resp.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.freshTodoList();
              this.freshCompletedList();
            } else {
              this.$message.error('Update  Error:' + resp.message);
            }
          });
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
    approvalRejectBatch() {
      if (this.copyArr.length === 0) {
        this.$message.error('Please select the item to be approved');
        return;
      }
      this.$prompt('input remark message', 'Remark', {
        confirmButtonText: 'reject',
        cancelButtonText: 'cancel',
      })
        .then(({ value }) => {
          let targetList = this.copyArr.slice();
          rejectApprovalBatch({ ids: targetList, noteMsg: value }).then((resp) => {
            if (resp.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.freshTodoList();
              this.freshCompletedList();
            } else {
              this.$message.error('Update  Error:' + resp.message);
            }
          });
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
  },
};
